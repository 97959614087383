import React, { useCallback, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { useIntl, navigate } from 'gatsby-plugin-intl';

import { checkCameraInputEffect } from '../utils/hooks';

import Layout from 'components/layout';

import RaisedCard from 'components/raised-card';
import SerialNumber from 'components/serial-number';
import Instructions from 'components/instructions';
import ScanLayout from './../components/scanning/scan-layout';

import Loader from 'components/loader';

const Main = ({ data, location }) => {
  const intl = useIntl();

  const [showStartScanningModal, setShowStartScanningModal] = useState(false);
  const [cameraInputAvailable, setCameraInputAvailable] = useState(null);

  const handleCheckButtonClick = useCallback(serialNumber => {
    navigate('/status', {
      state: {
        serialNumber,
      },
    });
  });

  const handleScanButtonClick = useCallback(() => {
    setShowStartScanningModal(true);
  });

  const handleTerminateScanFunctionality = useCallback(() => {
    setShowStartScanningModal(false);
  });

  useEffect(checkCameraInputEffect.bind(this, setCameraInputAvailable), []);

  const scanRelatedTexts = {
    instructionStep4: {
      scan: intl.formatMessage({
        id: 'main_page.how_to.instructions.04_check_status.scan.description',
      }),
      manual: intl.formatMessage({
        id: 'main_page.how_to.instructions.04_check_status.manual.description',
      }),
    },
  };

  const instructions = [
    {
      title: intl.formatMessage({
        id: 'main_page.how_to.instructions.01_take_out.title',
      }),
      desc: '',
      image: get(data, 'pi4.childImageSharp.fixed.src'),
    },
    {
      title: intl.formatMessage({
        id: 'main_page.how_to.instructions.02_power_supply.title',
      }),
      desc: intl.formatMessage({
        id: 'main_page.how_to.instructions.02_power_supply.description',
      }),
      image: get(data, 'usbc.childImageSharp.fixed.src'),
    },
    {
      title: intl.formatMessage({
        id: 'main_page.how_to.instructions.03_ethernet.title',
      }),
      desc: intl.formatMessage({
        id: 'main_page.how_to.instructions.03_ethernet.description',
      }),
      image: get(data, 'ethernet.childImageSharp.fixed.src'),
    },
    {
      title: intl.formatMessage({
        id: 'main_page.how_to.instructions.04_check_status.title',
      }),
      desc: cameraInputAvailable
        ? scanRelatedTexts.instructionStep4.scan
        : scanRelatedTexts.instructionStep4.manual,
      image: get(data, 'serial.childImageSharp.fixed.src'),
    },
  ];
  const scanImage = get(data, 'scan.childImageSharp.fixed.src');

  const placeholder = intl.formatMessage({
    id: 'serial_number_input.placeholder_example',
  });

  if (cameraInputAvailable === null) {
    return <Loader />;
  }

  return (
    <Layout location={location}>
      <ScanLayout
        scanInstructionImage={scanImage}
        onCheckButtonClick={handleCheckButtonClick}
        onTerminateScanFunctionality={handleTerminateScanFunctionality}
        initializeScanFunctionality={showStartScanningModal}
      />
      <p className="margin-top">
        {intl.formatMessage({ id: 'main_page.plugged_dev_thank_you' })}
      </p>
      <RaisedCard>
        <div className="h7">
          {intl.formatMessage({ id: 'main_page.encourage_card.title' })}
        </div>
        {cameraInputAvailable && (
          <p>
            {intl.formatMessage({
              id: 'main_page.encourage_card.scan.description',
            })}
          </p>
        )}
        {!cameraInputAvailable && (
          <p>
            {intl.formatMessage({
              id: 'main_page.encourage_card.manual.description',
            })}
          </p>
        )}
        <SerialNumber
          onCheckButtonClick={handleCheckButtonClick}
          placeholder={placeholder}
          useCamera={cameraInputAvailable}
          onScanButtonClick={handleScanButtonClick}
        />
      </RaisedCard>
      <Instructions
        instructions={instructions}
        header={intl.formatMessage({ id: 'main_page.how_to.title' })}
        description={intl.formatMessage({ id: 'main_page.how_to.description' })}
      />
      <p className="no-margin-bottom">
        {intl.formatMessage({ id: 'main_page.enter_serial_number' })}
      </p>
      <SerialNumber
        onCheckButtonClick={handleCheckButtonClick}
        placeholder={placeholder}
        className="margin-top-10"
        useCamera={cameraInputAvailable}
        onScanButtonClick={handleScanButtonClick}
      />
    </Layout>
  );
};

export default Main;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
    pi4: file(name: { eq: "pi4_sized" }) {
      childImageSharp {
        fixed(width: 500, height: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    ethernet: file(name: { eq: "ethernet" }) {
      childImageSharp {
        fixed(width: 500, height: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    serial: file(name: { eq: "serial_number" }) {
      childImageSharp {
        fixed(width: 500, height: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    usbc: file(name: { eq: "usbc" }) {
      childImageSharp {
        fixed(width: 550, height: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    scan: file(name: { eq: "scan_image" }) {
      childImageSharp {
        fixed(width: 466, height: 181) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
