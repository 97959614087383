import React from 'react';

import LoadingSpinner from 'components/loading-spinner';

export const Loader = () => (
  <div className="watchdog-loader">
    <LoadingSpinner />
  </div>
);

export default Loader;
